<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->

            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">
                    <h3 class="mb-0 text-white">Nastavenia stránky</h3>
                    <h2 class="">Darčekové poukazy</h2>
                </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-6 col-md-9 col-lg-12  order-xl-1">
                    <a-card shadow type="secondary">
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12" v-for="(field, index) in fieldData">
                                            <a-input alternative="" v-if="field.type === '0'"
                                                        :label="field.title"
                                                        input-classes="form-control-alternative"
                                                        v-model="fieldData[field.slug].value"
                                            />


                                            <a-input alternative="" v-if="field.type === '2'"
                                                        :label="field.title"
                                                        input-classes="form-control-alternative"
                                                        type="number"
                                                        v-model="fieldData[field.slug].value"
                                            />

                                            <div class="mb-4 multi-items" v-if="field.type === '3'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <image-upload :data="JSON.parse(fieldData[field.slug].value)" :title="field.title" :dat="field.slug" @changed="(e) => onChildChange(e,field.slug)"></image-upload>
                                            </div>
                                            <div class="mb-4 multi-items" v-if="field.type === '9'" >
                                                <label class="form-control-label">{{field.title}}</label>
                                               <a-form-item
                                                        v-for="(k, index) in form.getFieldValue('keys')"
                                                        :key="k"
                                                        v-bind="formItemLayout"
                                                        :required="false"

                                                >
                                                    <a-input placeholder="Názov štýlu" class="style-name" @change="(e) => varItems(e,field.slug)" v-model="styles.name[index]"/>
                                                    <a-input-number class="style-prop" placeholder="šírka" @change="(e) => varItems(e,field.slug)" v-model="styles.width[index]"/>
                                                    <a-input-number class="style-prop" placeholder="výška" @change="(e) => varItems(e,field.slug)" v-model="styles.height[index]" />
                                                    <a-icon
                                                            v-if="form.getFieldValue('keys').length > 1"
                                                            class="dynamic-delete-button"
                                                            type="minus-circle-o"
                                                            :disabled="form.getFieldValue('keys').length === 1"
                                                            @click="() => remove(k)"
                                                    />
                                                </a-form-item>
                                                <a-form-item >
                                                    <a-button type="dashed" style="width: 20%" @click="add">
                                                        <a-icon type="plus" /> Add field
                                                    </a-button>
                                                </a-form-item>


                                            </div>
                                            <div class="mb-4" v-if="field.type === '11'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <multi v-if="fieldData[field.slug].value" :data="{'item.image' : JSON.parse(fieldData[field.slug].value)}" gallery="true" :items="['item.image']" :editid="index" @changed="(e) => onChildChangeMulti(e,field.slug)"/>
                                                <multi v-else :data="{'item.image' : [] }" gallery="true" :items="['item.image']" :editid="index" @changed="(e) => onChildChangeMulti(e,field.slug)"/>
                                            </div>



                                            <div class="mb-4" v-if="field.type === '15'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <a-select v-if="fieldData[field.slug].value"
                                                          :mode="field.setup"
                                                        style="width: 100%" :defaultValue = "JSON.parse(fieldData[field.slug].value)"
                                                          @change="(e) => onChildChange(e,field.slug)" placeholder="Please select">
                                                    <a-select-option v-for="(op, indd) in fixedVals[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                                </a-select>

                                                <a-select v-else
                                                          style="width: 100%"
                                                          @change="(e) => onChildChange(e,field.slug)"
                                                          :mode="field.setup"
                                                          placeholder="Please select"
                                                >
                                                    <a-select-option v-for="(op, indd) in fixedVals[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                    >
                                                </a-select>

                                            </div>


                                            <div class="mb-4" v-if="field.type === '12' || field.type === '13' " >
                                                <label class="form-control-label">{{field.title}}</label>
                                                <a-select v-if="fieldData[field.slug].value"
                                                          mode="multiple" style="width: 100%" :defaultValue = "JSON.parse(fieldData[field.slug].value)"
                                                          @change="(e) => onChildChange(e,field.slug)" placeholder="Please select">
                                                    <a-select-option v-for="(op, indd) in options[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                                </a-select>

                                                <a-select v-else
                                                          mode="multiple"
                                                          style="width: 100%"
                                                          @change="(e) => onChildChange(e,field.slug)"
                                                          placeholder="Please select"
                                                >
                                                    <a-select-option v-for="(op, indd) in options[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                    >
                                                </a-select>

                                            </div>


                                            <div class="mb-4" v-if="field.type === '4'" >
                                                <label class="form-control-label">{{field.title}}</label>
                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                                          v-model="fieldData[field.slug].value"
                                                          :placeholder="field.title"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <a class="btn btn-secondary" :href="'/translate/' + this.id" v-if="fieldData['language'] === 'sk'" >Preložiť</a>   <button class="btn btn-primary">Uložiť</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getURL} from "../api";
    import {dataService} from "../_services";
    import Image from "./content/Image";
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import Multi from "./content/multi";

    import FormItem from 'ant-design-vue'
    import Vue from "vue"
    Vue.use(FormItem);
    let id = 0;
    export default {
        name: 'variables',
        components: {Multi,AFormItem,'image-upload' : Image},
        data() {
            return {
                url: getURL(),
                colorsto: JSON.parse(localStorage.colors),
                fieldData: {},
                options:[],
                fixedVals:{18: [
                        {"value":1,"label": "január"},
                        {"value":2,"label": "február"},
                        {"value":3,"label": "marec"},
                        {"value":4,"label": "apríl"},
                        {"value":5,"label": "máj"},
                        {"value":6,"label": "jún"},
                        {"value":7,"label": "júl"},
                        {"value":8,"label": "august"},
                        {"value":9,"label": "september"},
                        {"value":10,"label": "október"},
                        {"value":11,"label": "november"},
                        {"value":12,"label": "december"}],
                    17: [
                        {"value":1,"label": "1 noc"},
                        {"value":2,"label": "2 noci"},
                        {"value":3,"label": "3 noci"},
                        {"value":4,"label": "4 noci"},
                        {"value":5,"label": "5 nocí"},
                        {"value":6,"label": "6 nocí"},
                        {"value":7,"label": "7 nocí"},
                        {"value":8,"label": "8 nocí"},
                        {"value":9,"label": "9 nocí"},
                        {"value":10,"label": "10 nocí"},
                        {"value":11,"label": "11 nocí"},
                        {"value":12,"label": "12 nocí"}],
                },
                styles: {'name': [],'width': [],'height': []},
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 4},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 20},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 20, offset: 4},
                    },
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'dynamic_form_item'});
            this.form.getFieldDecorator('keys', {initialValue: [this.styles], preserve: true});
        },
        methods: {
            remove(k) {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                // We need at least one passenger
                if (keys.length === 1) {
                    return;
                }

                // can use data-binding to set
                form.setFieldsValue({
                    keys: keys.filter(key => key !== k),
                });
            },
            varItems(e,slug){
                this.fieldData[slug].value = JSON.stringify(this.styles);
            },
            onChildChangeMulti(value,slug)
            {

                for(var i in value){
                    this.fieldData[slug].value = JSON.stringify(Object.values(value[i]));
                }

            },
            add() {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                const nextKeys = keys.concat(id++);
                // can use data-binding to set
                // important! notify form to detect changes
                form.setFieldsValue({
                    keys: nextKeys,
                });
            },
            onChildChange(value,slug) {
                this.fieldData[slug].value = JSON.stringify(value);
            },

            handleSubmit() {
                dataService.axiosPost(this.$options.name, this.fieldData).then(results => {
                    if (results.data.success) {
                        this.$message.success('Nastavenia boli uložené');
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },
        },
        mounted() {
            dataService.axiosFetch(this.$options.name,"giftcards").then(results => {
                this.options = results.options;
                this.fieldData = results;
            });
        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .multi-items
    .add-button i
    {
        position: relative;
        top: -3px;}
    .dynamic-delete-button:hover {
        color: #777;
    }

    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .ant-select-selection--single {
        width: 100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;
    }
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button
    { position: relative;
        top: -6px;}

    .style-prop
    {float:left;width:20%;margin-right:1%;}
    .style-name
    {float:left;width:50%;
        margin-right:1%;}
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

</style>
